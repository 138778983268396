// Global styling for all PFD stations
$s-sticky-height: 3.125rem;
$m-sticky-height: 4.4375rem;
$nav_l-bar-height: 12rem;
$nav_s-bar-height: 5.25rem;

// z index
$nav_z-index-sticky: 1000004;
$nav_z-index-header: 1000003;

// ==================================================================
// typography

html {
	font-family: $font-stack;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-stack-headlines;
	font-weight: 900;
}

h1 {
	text-transform: uppercase;

	.section--primary & {
		color: color("shades", "white");
		margin-top: 0;
	}
}

h6 {
	text-transform: uppercase;
}

.theme-sidenav--toggle {
	z-index: $nav_z-index-sticky + 1;
	top: 20%;
}

.article__details {
	padding: 5.8rem 0 1.8rem 0;
}

.main-container {
	padding-top: 2rem;
}

.section--large-padding {
	padding: 4rem 0;
}

// Buttons
.btn-light {
	background-color: color("shades", "white");
	@extend %zoom-in;

	i {
		color: $primary-color;
		height: auto;
		line-height: inherit;
	}

	&:hover,
	&:focus {
		background-color: color("shades", "white");
	}

	&--transparent {
		opacity: .5;

		&:hover,
		&:focus {
			opacity: 1;
		}
	}
}

.btn-dark {
	background-color: $secondary-color;

	&:hover,
	&:focus,
	&:active {
		background-color: $secondary-color;
	}
}

// Header Nav Styles
.nav {
	&__stickybar__container,
	&__header__container {
		position: relative;
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		height: 100%;
	}

	&__stickybar {
		position: fixed;
		width: 100%;
		z-index: $nav_z-index-sticky;
		background: $primary-color;

		@include flex(row, center, space-between, nowrap);
		min-height: $s-sticky-height;
		max-height: $s-sticky-height;
		height: $s-sticky-height;

		@include media-breakpoint-up(m) {
			min-height: $m-sticky-height;
			max-height: $m-sticky-height;
			height: $m-sticky-height;
		}

		&__left, &__right {
			@include flex(row, center, center, nowrap);
			height: 100%;
		}

		&__left {
			@include flex(row, center, space-between, nowrap);
			flex: 0 0 90%;

			@include media-breakpoint-up(l) {
				flex: 0 0 80%;
			}

			&__last {
				display: flex;
				align-items: center;
				height: 100%;

				> * {
					@include media-breakpoint-up(m) {
						padding: 0 0.5rem;
					}
				}
			}

			.pfd-nav-login {
				.icon-link {
					overflow: visible;

					.badge--circle {
						top: -10px;
						right: -10px;
					}
				}
			}
		}

		&__container {
			@include flex(row, center, space-between, nowrap);
			position: relative;
		}

		.socials {
			@include flex(row, center, flex-start, nowrap);

			@include media-breakpoint-down(m) {
				width: 100%;
			}
		}
	}

	&__header {
		transition: all 0.25s linear;
		position: relative;
		top: $s-sticky-height;
		z-index: $nav_z-index-header;
		min-height: $nav-s-bar-height;

		@include media-breakpoint-up(m) {
			top: $m-sticky-height;
			min-height: $nav_l-bar-height;
		}

		&__container {
			@include flex(row, center, space-between, nowrap);
			padding: 1.75rem 0.75rem 0.75rem 0.75rem;

			@include media-breakpoint-up(sm) {
				@include flex(row, flex-start, flex-start, nowrap);
			}

			@include media-breakpoint-up(m) {
				@include flex(row, flex-start, space-between, nowrap);
			}

			@include media-breakpoint-up(xl) {
				@include flex(row, flex-start, flex-start, nowrap);
			}

			.is--homepage & {
				@include media-breakpoint-up(xl) {
					@include flex(row, center, flex-start, nowrap);
				}
			}
		}

		&__content {
			background-color: color("shades", "white");

			@include media-breakpoint-up(m) {
				max-height: $nav_l-bar-height;
				min-height: $nav_l-bar-height;
				height: $nav_l-bar-height;
			}
		}
	}

	.nav-search-bar__submit .mdi {
		height: $s-sticky-height;
		line-height: $s-sticky-height;
		color: color("shades", "white");

		@include media-breakpoint-up(m) {
			height: $m-sticky-height;
			line-height: $m-sticky-height;
		}
	}

	.nav-logo {
		height: auto;
		display: block;
	}

	// webchannels
	&-web-channels {
		position: absolute;
		min-height: $nav_s-bar-height;
		padding-top: rem(18px);
		padding-bottom: rem(18px);
		max-width: 100vw;

		@include media-breakpoint-up(m) {
			min-height: $nav_l-bar-height;
		}
	}

	.carousel-slider--webchannels {
		background: $background-light;
	}
}

.nav-logo img {
	height: rem(60px);

	@include media-breakpoint-up(m) {
		height: rem(120px);
	}

	@include media-breakpoint-up(xl) {
		height: rem(135px);
	}
}

.nav-service {
	@include flex(row, center, space-between, nowrap);

	&--webchannels {
		&:hover,
		&:focus {
			cursor: pointer;
		}
	}

	&__link {
		font-size: rem(20px);
		margin-left: rem(10px);
		text-transform: uppercase;
	}
}

.socials {
	.socials__link {
		@include media-breakpoint-up(m) {
			line-height: .9;
		}
	}
}

.socials__link,
.nav-search-toggle,
.nav-service__btn {
	@include media-breakpoint-down(m) {
		width: rem(32px);
		height: rem(32px);
		line-height: rem(24px);
		font-size: rem(32px);

		.mdi {
			font-size: rem(20px);
		}
	}
}

.socials__link,
.nav-service__btn,
.nav-search-toggle {
	.nav__stickybar__left & {
		@include media-breakpoint-down(m) {
			margin: rem(0 4px);
		}
	}
}

// Webradio Header
.webradio {
	color: $primary-color;
	font-size: rem(12px);

	@include media-breakpoint-up(m) {
		font-size: rem(18px);
		padding-left: 0;
	}

	@include media-breakpoint-up(xl) {
		padding-left: 40px;
	}

	&-title {
		line-height: rem(16px);
		margin-left: rem(10px);

		@include media-breakpoint-up(m) {
			line-height: rem(24px);
			margin-left: rem(20px);
		}
	}

	&-title-artist {
		color: $off-black;
	}

	&-now-playing {
		text-transform: uppercase;
	}

	&-now-playing,
	&-title-artist {
		font-weight: bold;
	}

	&-title-song {
		text-transform: none;
	}

	&__equalizer {
		display: none;

		@include media-breakpoint-up(m) {
			display: inline-block;
			height: rem(100px);
		}

		@include media-breakpoint-up(xl) {
			display: inline-block;
			height: rem(140px);
		}
	}
}

// Breaking news
.breaking-news__content__social {
	@include media-breakpoint-up(m) {
		flex: 0 0 25%;
	}
}

// Related news
.related-news__item {
	&:first-of-type {
		margin-top: 0;
	}
}

// Fullwidth section with primary background color
.section--primary {
	background-color: $primary-color;
	padding: 1rem;

	.card {
		margin-bottom: 0;
	}
}

// Sticky mobile footer (< 768px)
.sticky-mobile-footer {
	background-color: $primary-color;
	position: fixed;
	z-index: 2;
	bottom: 0;
	width: 100%;
	min-height: $s-sticky-height;
	max-height: $s-sticky-height;
	height: $s-sticky-height;

	.socials {
		@include flex(row, center, space-between, nowrap);
		height: $s-sticky-height;
		width: 100%;
	}
}

body {
	@include media-breakpoint-down(m) {
		padding-bottom: $s-sticky-height;
	}
}

.header {
	min-height: rem(150px);

	@include media-breakpoint-up(m) {
		min-height: rem(263px)
	}
}

// Round moderator box
.moderator--round {
	.nav__header & {
		position: absolute;
		top: 0;
		@include flex(row, center, flex-end, nowrap);

		@include media-breakpoint-up(sm) {
			position: static;
			margin-left: auto;
		}
	}

	.is--homepage & {
		@include media-breakpoint-up(xl) {
			display: none;
		}
	}

	&__name {
		color: $off-black;
		font-size: rem(12px);
		line-height: rem(16px);
		max-width: 60%;
		padding-left: rem(8px);

		@include media-breakpoint-between(m, l) {
			display: none;
		}

		@include media-breakpoint-up(m) {
			font-size: rem(18px);
			line-height: rem(22px);
			padding-left: rem(18px);
		}

		&__headline {
			font-weight: bold;
			display: block;

			@include media-breakpoint-up(m) {
				font-size: rem(20px);
			}
		}

		a {
			color: $off-black;
		}
	}

	&__image {
		width: rem(65px);
		border-radius: 50%;

		@include media-breakpoint-up(m) {
			width: rem(130px);
		}

		@include media-breakpoint-up(xl) {
			width: rem(160px);
		}

		@include media-breakpoint-up(xl) {
			border: 3px solid white;
			padding: 0;
		}
	}


	.icon-bar & {
		display: none;
	}

	.icon-bar--full & {
		@include media-breakpoint-up(xl) {
			@include flex(column, center, center, nowrap);
			padding: rem(30px 0);

			.moderator--round__image {
				margin-bottom: rem(20px);
				max-width: rem(180px);
			}

			.moderator--round__name {
				max-width: 88%;
				padding-left: 0;
				color: color("shades", "white");
				text-align: center;
			}
		}
	}
}

@include media-breakpoint-down(xl) {
	.icon-bar-teaser {
		display: none !important;
	}
}

@include media-breakpoint-up(xl) {
	.mobile-only {
		display: none !important;
	}
}

// Sticky, vertical icon bar
.icon-bar {
	display: none;

	@include media-breakpoint-up(m) {
		display: block;
		position: fixed;
		right: 0;
		top: 32vh;
		z-index: $nav_z-index-sticky;
		width: rem(75px);

		&__background {
			background-color: $primary-color;
			margin-bottom: 17px;
		}
	}


	&--animated {
		//transition: transform .3s ease-in-out;

		.icon-bar-teaser {
			display: none;
		}
	}

	&__container {
		position: relative;
	}

	&__tabs {
		@include flex(column, stretch, space-between, nowrap);
		margin: 0;

	}

	&__tab {
		&:not(:last-of-type) {
			border-bottom: 1px dotted color("shades", "white");
		}

		&__link {
			@include flex(row, center, center, nowrap);
			padding: rem(10px 15px);
		}

		&__btn {
			width: rem(46px);
			height: rem(46px);
			line-height: rem(46px);

			> svg {
				max-width: 47%;
				max-height: 55%;
			}
		}

		&__text {
			display: none;
		}

		a:hover {
			background-color: transparent;
		}
	}

	&--full {
		@include media-breakpoint-up(xl) {
			max-width: rem(270px);
			position: absolute;
			top: 0;
			right: 0;
			width: auto;
		}

		.icon-bar__tab__link {
			@include media-breakpoint-up(xl) {
				@include flex(row, center, flex-start, nowrap);
			}
		}

		.icon-bar__tab__btn {
			@include media-breakpoint-up(xl) {
				width: rem(56px);
				height: rem(56px);
				line-height: rem(56px);
			}

			i {
				@include media-breakpoint-up(xl) {
					font-size: rem(28px);
				}
			}

			> svg {
				@include media-breakpoint-up(xl) {
					max-width: 43%;
					max-height: 50%;
				}
			}
		}

		.icon-bar__tab__text {
			@include media-breakpoint-up(xl) {
				display: inline-block;
				color: color("shades", "white");
				font-weight: bold;
				font-size: rem(18px);
				text-transform: uppercase;
				padding-left: rem(15px);
			}
		}

		.icon-bar-collapse {
			@include media-breakpoint-up(xl) {
				display: none;
			}
		}

		.moderator--round__name, .moderator--round__name a {
			color: color("shades", "white");
		}
	}
}


.icon-bar--collapsed {
	@include media-breakpoint-down(xl) {
		transform: translate3d(100%, 0, 0);
	}

	@include media-breakpoint-up(xl) {
		&:not(.icon-bar--full) {
			transform: translate3d(100%, 0, 0);
		}
	}
}

.icon-bar-collapse {
	position: absolute;
	left: rem(-60px);
	background-color: $primary-color;
	height: rem(50px);
	width: rem(50px);
	border-radius: 50%;

	&__icon {
		nav & {
			height: rem(50px);
			line-height: rem(50px);
			width: rem(50px);
			color: color("shades", "white");
			text-align: center;
			font-size: 1.75rem;

			&::before {
				content: "\F142";
			}
		}
	}

	.icon-bar--collapsed & {
		.icon-bar-collapse__icon {
			&:before {
				content: "\FB87";
			}
		}
	}
}

// Header slider
.breaker-slider {
	.breaker__container {
		position: relative;
		margin: 0;
		min-height: rem(200px);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		@include media-breakpoint-up(l) {
			min-height: rem(400px);
		}
	}

	&__text__container {
		position: absolute;
		bottom: 10%;
		width: 100%;
	}

	.breaker__headline {
		@include flex(column, flex-start, flex-start, nowrap);

		> span {
			@extend h1;
			background-color: $primary-color;
			padding: rem(3px 10px);
			color: color("shades", "white");
			text-shadow: none;
			margin: rem(0 0 5px 0)
		}
	}
}

// Fixed right column (startpage only)
.col--right-fixed {
	display: none;

	@include media-breakpoint-up(xl) {
		@include flex(column, flex-end, flex-start, nowrap);
		position: relative;
		right: 0;
		top: rem(275px);
		flex: 0 0 21%;

		&__image {
			box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
			max-width: rem(270px);
			margin: rem(10px 0);
		}
	}
}

.has-fixed-col {
	justify-content: space-between;
}

// Breadcrumb
nav.breadcrumb {
	a {
		color: color("grey", "grey-blue");
		font-size: rem(18px);
	}
}

.breadcrumb {
	&::before {
		content: "\F142";
		font-family: "Material Design Icons";
		margin: rem(0 2px);
		line-height: .8;
	}

	&:last-child {
		color: $primary-color;
	}
}

// News detail
.abstract {
	font-style: normal;
}

.card .card-image .circle {
	border-radius: 50%;
}

// Team carousel slider
.carousel-slider--team {
	.card {
		background: transparent;

		.circle {
			border: 3px solid white;
		}
	}

	.slide {
		opacity: 1;
	}

	.slick-current .slide,
	.slide:hover {
		transform: none;
	}

	.carousel-slider__shorttext {
		font-size: rem(20px);
		text-align: center;
		color: color("shades", "white");
		padding-top: rem(5px);
		line-height: rem(25px);
		display: block;
	}
}

.nav-web-channels {
	.slick-prev:before, .slick-next:before {
		color: $primary-color;
	}
}

// Team styles
.team-card {
	box-shadow: none;
	background: none;
	height: auto;

	.card-image {
		border-radius: 50%;
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		width: rem(140px);
		height: rem(140px);

		@include media-breakpoint-up(sm) {
			width: rem(180px);
			height: rem(180px);
		}

		img {
			height: 100%;
			object-fit: cover;
		}
	}

	&--large {
		.card-image {
			width: rem(320px);
			height: rem(320px);
		}
	}
}

.team__question {
	p {
		margin: .5rem 0;
	}
}

.photoswipe-item {
	&.description--visible {
		figcaption {
			width: 100%;
			display: inline-block;
		}
	}
}

// webchannel slider width - should not collide with icon bar
.carousel-slider--webchannels {
	max-width: 100%;

	@include media-breakpoint-up(m) {
		max-width: 90%;
	}
}

.is--homepage {
	.carousel-slider--webchannels {
		@include media-breakpoint-up(xl) {
			max-width: 75%;
		}
	}
}

.nav__header__content__animate {
	@include flex(row, center, flex-start, nowrap);
	min-width: calc(100% - 150px);

	@include media-breakpoint-up(m) {
		min-width: calc(100% - 250px);
	}

	@include media-breakpoint-up(xl) {
		min-width: calc(100% - 280px);
	}

	.is--homepage & {
		@include media-breakpoint-up(xl) {
			width: auto;
		}
	}

	.moderator--round {
		@include media-breakpoint-down(sm) {
			opacity: 0;
			width: 55%;
			height: 100%;
			background-color: white;
			justify-content: flex-start;
			animation: blink 10s ease-in-out 5s infinite;
		}
	}
}

// News Slider Styles
// TODO: optimize slick-arrow effect on mouseover
.news-slider {
	.slick-arrow {
		&.is--hovered {
			@include media-breakpoint-up(m) {
				background-color: transparent;

				&::before {
					opacity: 1;
				}
			}

		}
	}
}

.footer-sitemap {
	border-top: rem(75px) solid $primary-color;
}

// Sidenav smaller
ul.sidenav__navigation li > a,
.sidenav .sidenav-close {
	font-size: rem(24px);
}

ul.sidenav__navigation ul li > a {
	font-size: rem(20px);
}

ul.sidenav__navigation > li {
	padding-top: rem(24px);
}

.sidenav {
	max-width: 80%;

	@include media-breakpoint-up(sm) {
		max-width: 55%;
	}

	@include media-breakpoint-up(m) {
		max-width: 42%;
	}

	@include media-breakpoint-up(xl) {
		max-width: 25%;
	}

	.sidenav-login__link {
		@include media-breakpoint-down(m) {
			margin-right: rem(16px);
			font-size: rem(14px);
		}
	}
}

.icon-bar--full .icon-bar__tab__btn > svg path,
.icon-bar__tab__btn > svg path,
.socials__link--svg > svg path {
	fill: $primary-color;
	stroke: $primary-color;
}

h1 {
	font-size: 2.25rem !important;
}
h2 {
	font-size: 1.6rem !important;
}
h3 {
	font-size: 1.4rem !important;
}
h4 {
	font-size: 1.2rem !important;
}
h5 {
	font-size: 1.1rem !important;
}

@include media-breakpoint-down(l) {
	h1 {
		font-size: 1.75rem !important;
	}
}


.pfd-team-slider {
	h2 {
		color: white;
		font-size: 45px;
	}
}

.pfd-webchannel-wrapper {
	background: white;
	position: fixed;
	width: 100%;
	z-index: 1;
	top: 71px;
	left: 0;

	@include media-breakpoint-down(m) {
		top: 50px;
	}
}

.carousel-slider--webchannels {
	padding-top: 18px;
	padding-bottom: 18px;
	transition: 0.3s ease-in-out;
}

// .is--homepage .carousel-slider--webchannels {
// 	@include media-breakpoint-down(l) {
// 		max-width: 100% !important;
// 	}
// }

.carousel-slider--webchannels.collapsed {
    padding-top: 0px;
    padding-bottom: 0px;
}

// fix clickdummy collapsed
.collapsed .nav-web-channels {
	visibility: hidden;
}

.news-slider .slick-dots {
	bottom: calc(23.575rem - 40px);
}

.news-slider .news-teaser__content {
    height: 23.575rem;
}

@include media-breakpoint-down(l) {
	.breaker-slider .breaker__headline > span {
		font-size: 1.6rem !important;
	}
}

.article__details {
	padding-top: 0 !important;
}

.ad__wrapper:not(.news__item):not(.news-teaser__item__inner):not(.related-news__item):not(.related-news__item):not(.ad__card)::before {
    display: none !important;
}

.slick-slider {
	opacity: 0;
}
.slick-initialized {
	opacity: 1 !important;
}

.btn-light {
	color: black;
}